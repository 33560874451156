<template>
    <div class="vItem">
        <div class="imgbox">
            <el-image fit="fit" :src="item.cover_url" alt="" style="width:100%;height:100%"></el-image>
            <div class="mark">
                <i class="el-icon-video-play cursor" @click="handleClick"></i>
            </div>
        </div>
        <div class="tit textOverflow"  @click="handleClick" :title="item.name|textFormat">{{item.name|textFormat }}</div>
        <div class="footer">
            <div  @click="handleClick">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/icon_eye.png" alt="">
                <span>{{item.play_count}}</span>
            </div>
            <div  @click="handleClick">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/icon_msg.png" alt="">
                <span>{{ item.comment_count }}</span>
            </div>
            <div  @click="handleClick">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/icon_heart.png" alt="">
                <span>{{ item.like_count }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return{

        }
    },
    methods:{
        handleClick(){
            
            if (this.IS_LOGIN) {
                let auth = this.$store.state.baseStore.auth.USER_AUTH.menu
                // 判断用户是否有权限
                console.log(auth)
                let is_exist = auth.find((item)=>{
                    console.log(item.identifier)
                    return item.identifier == "dga:tra"
    
                })
                if(!is_exist) return this.$message.error("您好，您当前尚未开通培训学院模块权限，如需开通请联系+86-21-62886580或者gbk-china@gbk-ingelheim.cn")

                this.$router.push({
                    path:"/college_detail",
                    query:{
                        parameter:this._encode({
                            video_id:this.item.id,
                        }),
                    },
                })
            } else {
                this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
                waitTodo: () => {
                    this.$router.push("/college")

                },
                });
                
            }
           
        }
    }
}
</script>
<style lang="less" scoped>
    .vItem{
        width: 220px;
        height: 241px;
        background: #FFFFFF;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.21);
        border-radius: 10px;
        margin-bottom: 24px;
        .imgbox{
            width: 220px;
            height: 162px;
            line-height: 162px;
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
                cursor: pointer;
            }
        }
        .tit{
            margin: 12px 12px 16px 12px;
            height: 20px;
            font-size: 14px;
            font-weight: 600;
            color: #1F292E;
            line-height: 20px;
            cursor: pointer;
        }
        .footer{
            display: flex;
            justify-content: space-between;
            padding: 0 12px 14px 12px;
            div{
                cursor: pointer;
                i{
                    font-size: 12px;
                    font-weight: 400;
                    color: #C0C4CC;
                }
                span{
                    display: inline-block;
                    padding-left:6px;
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #C0C4CC;
                    line-height: 17px;
                }
            }
            
        }
    }
    .mark {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 162px;
        line-height: 173px;
        text-align: center;
        .el-icon-video-play {
            font-size: 30px;
            color: #aaa;
        }
    }
    .mark:hover {
        background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
        color: #fff;
    }
    
</style>